
































































import Vue from "vue";
import * as _ from "lodash";
import fb from "firebase/app";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import Header from "@/components/global/Header.vue";
import { mapState, mapActions } from "vuex";
import MycButton from "@/components/global/buttons/MycButton.vue";

export default Vue.extend({
  name: "Group",
  components: {
    Header,
    MycButton,
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      group: {} as any,
      discussion: {} as any,
      newMessage: "",
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    async loadData() {
      this.group = await this.dbHelper.getDocFromCollection(
        "groups",
        this.$route.params.id
      );
      const where: any = { group_id: this.group.id };
      const disc = await this.dbHelper.getDocFromCollectionWithWhere(
        "discussions",
        where
      );
      this.dbHelper.getDocFromCollectionOnSnapshot(
        "discussions",
        disc.id,
        (item: any) => {
          this.discussion = item;
        }
      );
    },
    async send() {
      const FieldValue = fb.firestore.FieldValue;
      await this.dbHelper.updateDataToCollection(
        "discussions",
        this.discussion.id,
        {
          messages: FieldValue.arrayUnion({
            user_id: this.userData.id,
            content: this.newMessage,
          }),
        }
      );
      this.newMessage = "";
    },
  },
  async created() {
    this.logAction({ event_name: "group" });
    await this.loadData();
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
});
